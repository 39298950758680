// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QwHzXXupa7kWbeLlS1W7{\n  color:var(--axi-icon-body-color);\n}\n\n.cWn3l3IvKl2VJmxaysdX{\n  color:var(--axi-body-muted-color);\n}\n\n.JltKxaLRlu23gjYBV4PC{\n  color:var(--axi-icon-blue-color);\n}\n\n.opyhVD8Eow70MgRDAIow{\n  color:var(--axi-icon-green-color);\n}\n\n.hwumv6V8Gkn2Wo9piQU8{\n  color:var(--axi-icon-orange-color);\n}\n\n.YqJCakRPwfKituLpaaHo{\n  color:var(--orange-12);\n}\n\n.ipvO7q9czp95MLS5w78K{\n  color:var(--axi-icon-purple-color);\n}\n\n.GbJhZmsxFaCDEXfQbsnQ{\n  color:var(--axi-icon-red-color);\n}\n\n.oMo6RDISGrt4VhyZwhC4{\n  color:var(--axi-icon-teal-color);\n}\n\n.coygIs4MZi4qARst_UJw{\n  color:var(--yellow-10);\n}\n\n.OvDyYtjIwiAXaIwwYCBR{\n  color:var(--pink-10);\n}\n\n.hoRC5wrW7M028i1YJI1g{\n  color:var(--brown-10);\n}\n\n.CazcenTVZzze_2On7WFQ{\n  color:var(--gray-10);\n}\n\n.rImstrNSkEgEKbkxtrzH{\n  color:var(--axi-icon-green-color);\n}\n\n.g5SzortZBtg4rwuYV27A{\n  color:var(--axi-body-muted-color);\n}\n\n.uRlQeL1HC_GPP914HVbh{\n  color:var(--axi-icon-red-color);\n}\n\n.GwEATwy_8DC3htZl0_KG{\n  color:var(--axi-icon-orange-color);\n}\n\n.szAnpniY2UowG0gnJ9VQ{\n  color:white;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-default": "QwHzXXupa7kWbeLlS1W7",
	"colorDefault": "QwHzXXupa7kWbeLlS1W7",
	"color-muted": "cWn3l3IvKl2VJmxaysdX",
	"colorMuted": "cWn3l3IvKl2VJmxaysdX",
	"color-blue": "JltKxaLRlu23gjYBV4PC",
	"colorBlue": "JltKxaLRlu23gjYBV4PC",
	"color-green": "opyhVD8Eow70MgRDAIow",
	"colorGreen": "opyhVD8Eow70MgRDAIow",
	"color-orange": "hwumv6V8Gkn2Wo9piQU8",
	"colorOrange": "hwumv6V8Gkn2Wo9piQU8",
	"color-orange-dark": "YqJCakRPwfKituLpaaHo",
	"colorOrangeDark": "YqJCakRPwfKituLpaaHo",
	"color-purple": "ipvO7q9czp95MLS5w78K",
	"colorPurple": "ipvO7q9czp95MLS5w78K",
	"color-red": "GbJhZmsxFaCDEXfQbsnQ",
	"colorRed": "GbJhZmsxFaCDEXfQbsnQ",
	"color-teal": "oMo6RDISGrt4VhyZwhC4",
	"colorTeal": "oMo6RDISGrt4VhyZwhC4",
	"color-yellow": "coygIs4MZi4qARst_UJw",
	"colorYellow": "coygIs4MZi4qARst_UJw",
	"color-pink": "OvDyYtjIwiAXaIwwYCBR",
	"colorPink": "OvDyYtjIwiAXaIwwYCBR",
	"color-brown": "hoRC5wrW7M028i1YJI1g",
	"colorBrown": "hoRC5wrW7M028i1YJI1g",
	"color-grey": "CazcenTVZzze_2On7WFQ",
	"colorGrey": "CazcenTVZzze_2On7WFQ",
	"color-ok": "rImstrNSkEgEKbkxtrzH",
	"colorOk": "rImstrNSkEgEKbkxtrzH",
	"color-disabled": "g5SzortZBtg4rwuYV27A",
	"colorDisabled": "g5SzortZBtg4rwuYV27A",
	"color-error": "uRlQeL1HC_GPP914HVbh",
	"colorError": "uRlQeL1HC_GPP914HVbh",
	"color-warning": "GwEATwy_8DC3htZl0_KG",
	"colorWarning": "GwEATwy_8DC3htZl0_KG",
	"color-white": "szAnpniY2UowG0gnJ9VQ",
	"colorWhite": "szAnpniY2UowG0gnJ9VQ"
};
export default ___CSS_LOADER_EXPORT___;
