import { useLDClient, LDProvider } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { axiom } from '../axiom';
import { orgStore } from '../stores/OrgStore';
import { flags } from '../util/featureFlags/useFeatureFlags';

export const FeatureFlagProvider = ({ children }: { children: React.ReactNode }) => (
  <LDProvider
    clientSideID={axiom.launchDarklyClientId}
    deferInitialization={!axiom.launchDarklyClientId}
    options={{ diagnosticOptOut: true, bootstrap: flags }}
  >
    <OrgIDIdentifier>{children}</OrgIDIdentifier>
  </LDProvider>
);

const OrgIDIdentifier = observer(({ children }: { children: React.ReactNode }) => {
  const orgId = orgStore.activeOrgId;
  const ldClient = useLDClient();
  useEffect(() => {
    if (orgId) {
      void ldClient?.identify({ kind: 'organization', key: orgId });
    }
  }, [orgId, ldClient]);

  return <React.Fragment>{children}</React.Fragment>;
});
