// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J_Kl_p_Ct20mKSCooGUa{\n  display:inline-block;\n  position:relative;\n  width:80px;\n  height:80px;\n  transform:scale(0.6);\n}\n\n.J_Kl_p_Ct20mKSCooGUa div{\n  animation:J_Kl_p_Ct20mKSCooGUa 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  transform-origin:40px 40px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:after{\n  content:\" \";\n  display:block;\n  position:absolute;\n  width:7px;\n  height:7px;\n  border-radius:50%;\n  background:var(--axi-body-alt-color);\n  margin:-4px 0 0 -4px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(1){\n  animation-delay:-0.036s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(1):after{\n  top:63px;\n  left:63px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(2){\n  animation-delay:-0.072s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(2):after{\n  top:68px;\n  left:56px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(3){\n  animation-delay:-0.108s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(3):after{\n  top:71px;\n  left:48px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(4){\n  animation-delay:-0.144s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(4):after{\n  top:72px;\n  left:40px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(5){\n  animation-delay:-0.18s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(5):after{\n  top:71px;\n  left:32px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(6){\n  animation-delay:-0.216s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(6):after{\n  top:68px;\n  left:24px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(7){\n  animation-delay:-0.252s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(7):after{\n  top:63px;\n  left:17px;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(8){\n  animation-delay:-0.288s;\n}\n\n.J_Kl_p_Ct20mKSCooGUa div:nth-child(8):after{\n  top:56px;\n  left:12px;\n}\n\n@keyframes J_Kl_p_Ct20mKSCooGUa{\n  0%{\n    transform:rotate(0deg);\n  }\n  100%{\n    transform:rotate(360deg);\n  }\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spin": "J_Kl_p_Ct20mKSCooGUa"
};
export default ___CSS_LOADER_EXPORT___;
