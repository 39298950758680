import React, { type CSSProperties } from 'react';
import { Link as RLink, type LinkProps as RLinkProps, NavLink, type NavLinkProps } from 'react-router-dom';

import { browserHistory } from '../util/browserHistory';
import { goBackIfPrevious as goBackIfPreviousUtil } from '../util/LocationHistoryProvider';
import { cn } from '../util/styles';

interface BaseLinkProps {
  disabled?: boolean;
  goBackIfPrevious?: boolean;
  historyReplace?: boolean;
  noUnderline?: boolean;
}

type LinkProps = AnchorProps | ReactLinkProps | ReactNavLinkProps;

/**
 * return regular anchor or react router Link depending on href or to respectively.
 */
export const Link = (props: LinkProps) => {
  if (isAnchor(props)) {
    return <AnchorLink {...props} />;
  }

  return <ReactRouterLink {...props} />;
};

export interface AnchorProps
  extends BaseLinkProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Partial<ReactLinkProps>,
    Partial<Omit<ReactNavLinkProps, 'className'>> {
  href: string; // Make href required if you want to render an anchor.
  external?: boolean;
}

const AnchorLink = ({
  activeClassName,
  activeStyle,
  className,
  disabled,
  exact,
  external,
  goBackIfPrevious,
  historyReplace,
  location,
  innerRef,
  isActive,
  sensitive,
  strict,
  noUnderline,
  ...passthrough
}: AnchorProps) => {
  const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <a
      className={cn(
        'undeline text-blue-11 hover:text-blue-12 focus:text-blue-12 active:text-blue-12',
        className,
        { 'no-underline hover:no-underline': noUnderline },
        { underline: !noUnderline },
        // Really shouldn't be using `disabled` on links but we do in a few places.
        { 'cursor-not-allowed': disabled }
      )}
      {...externalProps}
      {...passthrough}
    />
  );
};

interface ReactLinkProps extends BaseLinkProps, Omit<RLinkProps, 'style'> {
  style?: CSSProperties;
}

export interface ReactNavLinkProps extends BaseLinkProps, Omit<NavLinkProps, 'activeClassName' | 'style'> {
  activeClassName?: string; // Make activeClassName required if you want to render a NavLink.
  style?: CSSProperties;
}

const ReactRouterLink = (props: ReactLinkProps | ReactNavLinkProps) => {
  const {
    className,
    disabled,
    goBackIfPrevious,
    historyReplace,
    noUnderline,
    onClick: parentOnClick,
    to,
    ...passthrough
  } = props;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      e.preventDefault();

      return;
    }

    // Use history.back instead of navigating forward if the previousPath is the same as our `to`.
    // Keeps us from filling up the browser history with nonsense.
    if (goBackIfPrevious && goBackIfPreviousUtil(to)) {
      e.preventDefault();
    }

    if (historyReplace && typeof to === 'string') {
      browserHistory.replace(to);
      e.preventDefault();
    }

    if (parentOnClick) {
      parentOnClick(e);
    }
  };

  if (isNavLink(props)) {
    return (
      <NavLink
        to={to}
        onClick={onClick}
        className={cn(
          { 'no-underline hover:no-underline': noUnderline },
          { underline: !noUnderline },
          // Really shouldn't be using `disabled` on links but we do in a few places.
          { 'cursor-not-allowed': disabled },
          typeof className === 'string' ? className : undefined
        )}
        {...passthrough}
      />
    );
  } else {
    return (
      <RLink
        to={to}
        onClick={onClick}
        className={cn(
          { 'no-underline hover:no-underline': noUnderline },
          { underline: !noUnderline },
          // Really shouldn't be using `disabled` on links but we do in a few places.
          { 'cursor-not-allowed': disabled },
          typeof className === 'string' ? className : undefined
        )}
        {...passthrough}
      />
    );
  }
};

function isAnchor(props: LinkProps): props is AnchorProps {
  return (props as any).href !== undefined;
}

function isNavLink(props: LinkProps): props is ReactNavLinkProps {
  return props.to !== undefined && (props as any).activeClassName !== undefined;
}
