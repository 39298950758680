// fad - Duotone

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBookmark } from '@fortawesome/pro-duotone-svg-icons/faBookmark';
import { faChartColumn } from '@fortawesome/pro-duotone-svg-icons/faChartColumn';
import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons/faMagnifyingGlass';
import { faPlusHexagon } from '@fortawesome/pro-duotone-svg-icons/faPlusHexagon';
import { faRocketLaunch } from '@fortawesome/pro-duotone-svg-icons/faRocketLaunch';

library.add(faBookmark, faChartColumn, faMagnifyingGlass, faPlusHexagon, faRocketLaunch);
