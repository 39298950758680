import { action, type IReactionDisposer, observable, reaction, makeObservable } from 'mobx';
import { createContext } from 'react';

import { axiom } from '../axiom';
import { dashApi } from '../shared/dash';

import { SimpleOperationsTracker, StoreBase } from './StoreBase';

interface Upgrade {
  currentVersion: string;
}

export class UpgradeStore extends StoreBase {
  @observable
  public upgrade: Upgrade = {
    currentVersion: 'Unknown Version',
  };

  @observable
  public upgradeInProgress: boolean = false;

  public getCurrentVersionOps = new SimpleOperationsTracker('getCurrentVersion');

  private refreshDisposer: IReactionDisposer;

  constructor() {
    super();

    makeObservable(this);

    this.refreshDisposer = reaction(
      () => this.upgrade.currentVersion,
      () => {
        const currentVersion = this.upgrade.currentVersion;
        const clientVersion = axiom.version;
        if (currentVersion && clientVersion) {
          // we just can't decide whether we should prefix the string with 'v' or not...
          const versionTag =
            !currentVersion.startsWith('v') && clientVersion.startsWith('v') ? `v${currentVersion}` : currentVersion;
          const E2E_TESTING = process.env.E2E_TESTING === 'true';
          // Don't reload during E2E_TESTING, it will cause an infinite loop because
          // the upgrade API is mocked.
          if (clientVersion !== versionTag && !E2E_TESTING) {
            window.location.reload();
          }
        }
      }
    );
  }

  public dispose() {
    this.refreshDisposer();
  }

  @action.bound
  public getCurrentVersion(): void {
    void this.operate(this.getCurrentVersionOps.operation, dashApi.getVersion(), (data) => {
      this.upgrade.currentVersion = data.currentVersion;
    });
  }
}

export const UpgradeStoreContext = createContext<UpgradeStore | null>(null);
UpgradeStoreContext.displayName = 'UpgradeStoreContext';
