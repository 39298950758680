// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b8uOzfO45zXrx_qzeiQW{\n  position:relative;\n  color:var(--axi-body-color);\n}\n\n.dnQ3xZyryi1HCgFdZe6t{\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n}\n\n.ZPj1PTRL_ZnI0rmJC_vb{\n  font-size:1.4em;\n  margin-right:8px;\n}\n\n.rQ75WFonpmXx6xGqlwJp{\n  line-height:14px;\n  font-size:14px;\n  font-weight:500;\n  word-break:break-word;\n}\n\n.CQ4tV_HC4kQcs4UrWNH3{\n  margin-top:8px;\n  color:var(--axi-body-alt-color);\n  overflow:auto;\n}\n\n.wQqBLLb6uT3PWLQRvnon{\n  font-size:1.5em;\n  margin-right:4px;\n  color:var(--axi-body-color);\n}\n\n.NTVhVvDUnctdzKBCaTER{\n  display:flex;\n  align-items:center;\n  padding:0 !important;\n  margin:0;\n}\n.NTVhVvDUnctdzKBCaTER .CQ4tV_HC4kQcs4UrWNH3{\n  white-space:nowrap;\n  margin:0;\n  padding-right:12px;\n}\n.NTVhVvDUnctdzKBCaTER .CQ4tV_HC4kQcs4UrWNH3 a{\n  color:var(--blue-11);\n  text-decoration:none;\n  font-size:13px;\n  font-weight:500;\n  display:block;\n  padding:6px 16px;\n  font-family:Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;\n}\n.NTVhVvDUnctdzKBCaTER .rQ75WFonpmXx6xGqlwJp{\n  white-space:nowrap;\n  padding-left:4px;\n  flex-grow:2;\n  font-size:13px;\n  font-weight:400;\n  font-family:Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;\n}\n.NTVhVvDUnctdzKBCaTER .ZPj1PTRL_ZnI0rmJC_vb{\n  position:relative;\n  padding:12px;\n  color:var(--axi-color-success);\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "b8uOzfO45zXrx_qzeiQW",
	"title": "dnQ3xZyryi1HCgFdZe6t",
	"icon": "ZPj1PTRL_ZnI0rmJC_vb",
	"message": "rQ75WFonpmXx6xGqlwJp",
	"description": "CQ4tV_HC4kQcs4UrWNH3",
	"close": "wQqBLLb6uT3PWLQRvnon",
	"progressSuccess": "NTVhVvDUnctdzKBCaTER"
};
export default ___CSS_LOADER_EXPORT___;
