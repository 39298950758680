/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  APLRequestWithOptions,
  AplResult,
  AuthStatus,
  AvailableCapabilities,
  BillingChange,
  CreateDataset,
  CreateDatasetParams,
  CreateFineGrainToken,
  CreateFineGrainTokenResponse,
  CreateFlowReplay,
  CreateTeam,
  CreateToken,
  CreateTokenResponse,
  CreateUserRequest,
  Dashboard,
  DashboardImportWarning,
  DashboardWithId,
  DatadogMetricsMetadata,
  DatasetCoreInfoWithFields,
  DatasetField,
  DatasetFields,
  DatasetFieldsInfo,
  DatasetSpec,
  DatasetSpecWithExtras,
  FineGrainToken,
  FlowConfig,
  FlowConfigWithId,
  FlowDestination,
  FlowDestinationWithId,
  FlowReplayWithId,
  GetDashboardsParams,
  GetDatasetParams,
  GetDatasetsParams,
  GetLibrariesParams,
  GetMonitorHistoryParams,
  GetStarredQueriesParams,
  GettingStarted,
  GettingStartedPayload,
  GetVirtualFieldsParams,
  Group,
  IngestStatus,
  IngestToDatasetParams,
  Ingress,
  IngressCapability,
  IntegrationConfig,
  IntegrationConfigMeta,
  IntegrationConfigSources,
  IntegrationConnectProps,
  IntegrationNextStep,
  Invoices,
  LibraryWithSymbols,
  License,
  LogoutUserEverywhereParams,
  LogoutUsersEverywhereParams,
  Monitor,
  MonitorHistory,
  Notifier,
  OAuthProvider,
  OnboardingPayload,
  OnboardingStatus,
  Org,
  OrgSharedAccessKeys,
  OrgStatus,
  PaymentMethod,
  Policy,
  Portal,
  PostOrg,
  PostStorage,
  QueryAplParams,
  QueryDatasetParams,
  QueryError,
  QueryRequestWithOptions,
  RegenerateFineGrainToken,
  RegenerateToken,
  RegisteredApp,
  Result,
  Role,
  RolesList,
  RotateSharedAccessKeysParams,
  Session,
  Settings,
  StarredQuery,
  StarredQueryWithId,
  Stats,
  Storage,
  StripeSetupIntent,
  Team,
  Token,
  TrimOptions,
  UpdateDataset,
  UpdateUserSettings,
  Usage,
  UsageDateRange,
  User,
  UserRole,
  UserSettings,
  UserUpdate,
  VacuumResult,
  VersionPayload,
  VirtualField,
  VirtualFieldWithId,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class DashApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Returns the app ids that are registered.
   *
   * @tags dash
   * @name GetRegisteredApps
   * @request GET:/apps/registered
   * @secure
   * @response `200` `(RegisteredApp)[]` Apps
   */
  getRegisteredApps = (params: RequestParams = {}) =>
    this.request<RegisteredApp[], any>({
      path: `/apps/registered`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Registered or updates the app.
   *
   * @tags dash
   * @name PostRegisteredApps
   * @request POST:/apps/registered
   * @secure
   * @response `200` `RegisteredApp` App
   */
  postRegisteredApps = (payload: RegisteredApp, params: RequestParams = {}) =>
    this.request<RegisteredApp, any>({
      path: `/apps/registered`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Gets a single app.
   *
   * @tags dash
   * @name GetRegisteredApp
   * @request GET:/apps/registered/{id}
   * @secure
   * @response `200` `RegisteredApp` App
   */
  getRegisteredApp = (id: string, params: RequestParams = {}) =>
    this.request<RegisteredApp, any>({
      path: `/apps/registered/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Unregisters the app.
   *
   * @tags dash
   * @name DeleteRegisteredApp
   * @request DELETE:/apps/registered/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteRegisteredApp = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/apps/registered/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteAccount
   * @request DELETE:/auth/account
   * @secure
   * @response `204` `void` (empty)
   */
  deleteAccount = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/auth/account`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetAuthStatus
   * @request GET:/auth/status
   * @response `200` `AuthStatus` AuthStatus
   */
  getAuthStatus = (params: RequestParams = {}) =>
    this.request<AuthStatus, any>({
      path: `/auth/status`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDashboards
   * @request GET:/dashboards
   * @secure
   * @response `200` `(DashboardWithId)[]` DashboardWithId
   */
  getDashboards = (query: GetDashboardsParams, params: RequestParams = {}) =>
    this.request<DashboardWithId[], any>({
      path: `/dashboards`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateDashboard
   * @request POST:/dashboards
   * @secure
   * @response `200` `DashboardWithId` DashboardWithId
   */
  createDashboard = (payload: Dashboard, params: RequestParams = {}) =>
    this.request<DashboardWithId, any>({
      path: `/dashboards`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags dash
 * @name CreateDatadogDashboard
 * @request POST:/dashboards/datadog
 * @secure
 * @response `200` `{
    dashboard?: DashboardWithId,
    warnings?: (DashboardImportWarning)[],

}` success, returns the dashboard and a list of non-fatal warnings
 */
  createDatadogDashboard = (
    payload: {
      /** specifically the value returned when calling https://docs.datadoghq.com/api/latest/dashboards/#get-a-dashboard */
      dashboard: Record<string, any>;
      /**
       * A mapping of metric names to metrict metadatas, with the metric name being the objects keys and the metric metadatas being the values.
       * To get a list of a list of metric names from the dd api, see https://docs.datadoghq.com/api/latest/metrics/#get-a-list-of-metrics
       */
      metrics?: DatadogMetricsMetadata;
    },
    params: RequestParams = {},
  ) =>
    this.request<
      {
        dashboard?: DashboardWithId;
        warnings?: DashboardImportWarning[];
      },
      any
    >({
      path: `/dashboards/datadog`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDashboard
   * @request GET:/dashboards/{id}
   * @secure
   * @response `200` `DashboardWithId` DashboardWithId
   */
  getDashboard = (id: string, params: RequestParams = {}) =>
    this.request<DashboardWithId, any>({
      path: `/dashboards/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateDashboard
   * @request PUT:/dashboards/{id}
   * @secure
   * @response `200` `DashboardWithId` DashboardWithId
   */
  updateDashboard = (id: string, payload: Dashboard, params: RequestParams = {}) =>
    this.request<DashboardWithId, any>({
      path: `/dashboards/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteDashboard
   * @request DELETE:/dashboards/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteDashboard = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/dashboards/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDatasets
   * @request GET:/datasets
   * @secure
   * @response `200` `(DatasetSpecWithExtras)[]` DatasetSpec
   */
  getDatasets = (query: GetDatasetsParams, params: RequestParams = {}) =>
    this.request<DatasetSpecWithExtras[], any>({
      path: `/datasets`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateDataset
   * @request POST:/datasets
   * @secure
   * @response `200` `DatasetSpec` DatasetSpec
   */
  createDataset = (query: CreateDatasetParams, payload: CreateDataset, params: RequestParams = {}) =>
    this.request<DatasetSpec, any>({
      path: `/datasets`,
      method: "POST",
      query: query,
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name QueryApl
   * @request POST:/datasets/_apl
   * @secure
   * @response `200` `AplResult` AplResult
   * @response `default` `QueryError` User or system error
   */
  queryApl = (query: QueryAplParams, payload: APLRequestWithOptions, params: RequestParams = {}) =>
    this.request<AplResult, QueryError>({
      path: `/datasets/_apl`,
      method: "POST",
      query: query,
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDatasetsFields
   * @request GET:/datasets/_fields
   * @secure
   * @response `200` `(DatasetFields)[]` DatasetFields
   */
  getDatasetsFields = (params: RequestParams = {}) =>
    this.request<DatasetFields[], any>({
      path: `/datasets/_fields`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDatasetsHistoryQuery
   * @request GET:/datasets/_history/{id}
   * @secure
   * @response `200` `StarredQueryWithId` StarredQueryWithId
   */
  getDatasetsHistoryQuery = (id: string, params: RequestParams = {}) =>
    this.request<StarredQueryWithId, any>({
      path: `/datasets/_history/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDatasetsInfo
   * @request GET:/datasets/_info
   * @secure
   * @response `200` `(DatasetFieldsInfo)[]` DatasetFieldsInfo
   */
  getDatasetsInfo = (params: RequestParams = {}) =>
    this.request<DatasetFieldsInfo[], any>({
      path: `/datasets/_info`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDatasetsStats
   * @request GET:/datasets/_stats
   * @secure
   * @response `200` `Stats` Stats
   */
  getDatasetsStats = (params: RequestParams = {}) =>
    this.request<Stats, any>({
      path: `/datasets/_stats`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name IngestToDataset
   * @request POST:/datasets/ingest/{id}
   * @secure
   * @response `200` `IngestStatus` IngestResult
   */
  ingestToDataset = ({ id, ...query }: IngestToDatasetParams, payload: File, params: RequestParams = {}) =>
    this.request<IngestStatus, any>({
      path: `/datasets/ingest/${id}`,
      method: "POST",
      query: query,
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDataset
   * @request GET:/datasets/{id}
   * @secure
   * @response `200` `DatasetSpecWithExtras` DatasetSpec
   */
  getDataset = ({ id, ...query }: GetDatasetParams, params: RequestParams = {}) =>
    this.request<DatasetSpecWithExtras, any>({
      path: `/datasets/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateDataset
   * @request PUT:/datasets/{id}
   * @secure
   * @response `200` `DatasetSpec` DatasetSpec
   */
  updateDataset = (id: string, payload: UpdateDataset, params: RequestParams = {}) =>
    this.request<DatasetSpec, any>({
      path: `/datasets/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteDataset
   * @request DELETE:/datasets/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteDataset = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/datasets/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateField
   * @request PUT:/datasets/{id}/fields/{fieldId}
   * @secure
   * @response `200` `DatasetField` DatasetField
   */
  updateField = (id: string, fieldId: string, payload: DatasetField, params: RequestParams = {}) =>
    this.request<DatasetField, any>({
      path: `/datasets/${id}/fields/${fieldId}`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetDatasetInfo
   * @request GET:/datasets/{id}/info
   * @secure
   * @response `200` `DatasetCoreInfoWithFields` DatasetCoreInfo
   */
  getDatasetInfo = (id: string, params: RequestParams = {}) =>
    this.request<DatasetCoreInfoWithFields, any>({
      path: `/datasets/${id}/info`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name QueryDataset
   * @request POST:/datasets/{id}/query
   * @secure
   * @response `200` `Result` Result
   */
  queryDataset = ({ id, ...query }: QueryDatasetParams, payload: QueryRequestWithOptions, params: RequestParams = {}) =>
    this.request<Result, any>({
      path: `/datasets/${id}/query`,
      method: "POST",
      query: query,
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name TrimDataset
   * @request POST:/datasets/{id}/trim
   * @secure
   * @response `200` `void` TrimResult
   */
  trimDataset = (id: string, payload: TrimOptions, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/datasets/${id}/trim`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name VacuumDataset
   * @request POST:/datasets/{id}/vacuum
   * @secure
   * @response `200` `VacuumResult` VacuumResult
   * @response `429` `void` TooManyVacuumRequests
   */
  vacuumDataset = (id: string, params: RequestParams = {}) =>
    this.request<VacuumResult, void>({
      path: `/datasets/${id}/vacuum`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve list of flow configurations
   *
   * @tags dash
   * @name ListFlows
   * @summary Retrieve list of flow configurations
   * @request GET:/flows
   * @secure
   * @response `200` `(FlowConfigWithId)[]` (empty)
   */
  listFlows = (params: RequestParams = {}) =>
    this.request<FlowConfigWithId[], any>({
      path: `/flows`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new flow configuration
   *
   * @tags dash
   * @name CreateFlow
   * @summary Create a new flow configuration
   * @request POST:/flows
   * @secure
   * @response `200` `FlowConfigWithId` (empty)
   */
  createFlow = (payload: FlowConfig, params: RequestParams = {}) =>
    this.request<FlowConfigWithId, any>({
      path: `/flows`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve list of flow destination configurations
   *
   * @tags dash
   * @name ListFlowDestinations
   * @summary Retrieve list of flow destination confrigurations
   * @request GET:/flows/destinations
   * @secure
   * @response `200` `(FlowDestinationWithId)[]` (empty)
   */
  listFlowDestinations = (params: RequestParams = {}) =>
    this.request<FlowDestinationWithId[], any>({
      path: `/flows/destinations`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new flow destination configuration
   *
   * @tags dash
   * @name CreateFlowDestination
   * @summary Create a new flow destination configuration
   * @request POST:/flows/destinations
   * @secure
   * @response `200` `FlowDestinationWithId` (empty)
   */
  createFlowDestination = (payload: FlowDestination, params: RequestParams = {}) =>
    this.request<FlowDestinationWithId, any>({
      path: `/flows/destinations`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get flow destination configuration
   *
   * @tags dash
   * @name GetFlowDestination
   * @summary Get flow destination configuration
   * @request GET:/flows/destinations/{id}
   * @secure
   * @response `200` `FlowDestinationWithId` (empty)
   */
  getFlowDestination = (id: string, params: RequestParams = {}) =>
    this.request<FlowDestinationWithId, any>({
      path: `/flows/destinations/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update flow destination configuration
   *
   * @tags dash
   * @name UpdateFlowDestination
   * @summary Update flow destination configuration
   * @request PUT:/flows/destinations/{id}
   * @secure
   * @response `200` `FlowDestinationWithId` (empty)
   */
  updateFlowDestination = (id: string, payload: FlowDestination, params: RequestParams = {}) =>
    this.request<FlowDestinationWithId, any>({
      path: `/flows/destinations/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Delete flow destination configuration
   *
   * @tags dash
   * @name DeleteFlowDestination
   * @summary Delete flow destination configuration
   * @request DELETE:/flows/destinations/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteFlowDestination = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/flows/destinations/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Get a flow replay
   *
   * @tags dash
   * @name GetFlowReplay
   * @summary Get a flow replay
   * @request GET:/flows/{flowId}/replay/{id}
   * @secure
   * @response `200` `FlowReplayWithId` (empty)
   */
  getFlowReplay = (flowId: string, id: string, params: RequestParams = {}) =>
    this.request<FlowReplayWithId, any>({
      path: `/flows/${flowId}/replay/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Stop a flow replay
   *
   * @tags dash
   * @name StopFlowReplay
   * @summary Stop a flow replay
   * @request POST:/flows/{flowId}/replay/{id}/stop
   * @secure
   * @response `204` `void` (empty)
   */
  stopFlowReplay = (flowId: string, id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/flows/${flowId}/replay/${id}/stop`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * @description Get a flow configuration by id
   *
   * @tags dash
   * @name GetFlow
   * @summary Get a flow configuration
   * @request GET:/flows/{id}
   * @secure
   * @response `200` `FlowConfigWithId` (empty)
   */
  getFlow = (id: string, params: RequestParams = {}) =>
    this.request<FlowConfigWithId, any>({
      path: `/flows/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update a flow configuration
   *
   * @tags dash
   * @name UpdateFlow
   * @summary Update a flow configuration
   * @request PUT:/flows/{id}
   * @secure
   * @response `200` `FlowConfigWithId` (empty)
   */
  updateFlow = (id: string, payload: FlowConfig, params: RequestParams = {}) =>
    this.request<FlowConfigWithId, any>({
      path: `/flows/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Delete a flow configuration
   *
   * @tags dash
   * @name DeleteFlow
   * @summary Delete a flow configuration
   * @request DELETE:/flows/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteFlow = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/flows/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve list of flow replays
   *
   * @tags dash
   * @name ListFlowReplays
   * @summary Retrieve list of flow replays
   * @request GET:/flows/{id}/replays
   * @secure
   * @response `200` `(FlowReplayWithId)[]` (empty)
   */
  listFlowReplays = (id: string, params: RequestParams = {}) =>
    this.request<FlowReplayWithId[], any>({
      path: `/flows/${id}/replays`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create and start a new flow replay
   *
   * @tags dash
   * @name CreateFlowReplay
   * @summary Create and start a new flow replay
   * @request POST:/flows/{id}/replays
   * @secure
   * @response `202` `FlowReplayWithId` (empty)
   */
  createFlowReplay = (id: string, payload: CreateFlowReplay, params: RequestParams = {}) =>
    this.request<FlowReplayWithId, any>({
      path: `/flows/${id}/replays`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetGettingStarted
   * @request GET:/getting-started
   * @secure
   * @response `200` `(GettingStarted)[]` GettingStarted
   */
  getGettingStarted = (params: RequestParams = {}) =>
    this.request<GettingStarted[], any>({
      path: `/getting-started`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateGettingStarted
   * @request POST:/getting-started
   * @secure
   * @response `204` `void` (empty)
   */
  createGettingStarted = (payload: GettingStartedPayload, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/getting-started`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name ListAllIngress
   * @request GET:/ingress
   * @secure
   * @response `200` `(Ingress)[]` Ingress
   */
  listAllIngress = (params: RequestParams = {}) =>
    this.request<Ingress[], any>({
      path: `/ingress`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateIngress
   * @request POST:/ingress
   * @secure
   * @response `200` `Ingress` Ingress
   */
  createIngress = (payload: Ingress, params: RequestParams = {}) =>
    this.request<Ingress, any>({
      path: `/ingress`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name ListCapabilities
   * @request GET:/ingress/capabilities
   * @secure
   * @response `200` `(IngressCapability)[]` IngressCapability
   */
  listCapabilities = (params: RequestParams = {}) =>
    this.request<IngressCapability[], any>({
      path: `/ingress/capabilities`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetIngress
   * @request GET:/ingress/{id}
   * @secure
   * @response `200` `Ingress` Ingress
   */
  getIngress = (id: string, params: RequestParams = {}) =>
    this.request<Ingress, any>({
      path: `/ingress/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateIngress
   * @request PUT:/ingress/{id}
   * @secure
   * @response `200` `Ingress` Ingress OK
   */
  updateIngress = (id: string, payload: Ingress, params: RequestParams = {}) =>
    this.request<Ingress, any>({
      path: `/ingress/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteIngress
   * @request DELETE:/ingress/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteIngress = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/ingress/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetIntegrationConfigs
   * @request GET:/integrations/configs
   * @secure
   * @response `200` `(IntegrationConfig)[]` IntegrationConfig
   */
  getIntegrationConfigs = (params: RequestParams = {}) =>
    this.request<IntegrationConfig[], any>({
      path: `/integrations/configs`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteIntegration
   * @request DELETE:/integrations/configs/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteIntegration = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/integrations/configs/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name SetIntegrationConfigMeta
   * @request PUT:/integrations/configs/{id}/meta
   * @secure
   * @response `204` `void` IntegrationConfigMeta
   */
  setIntegrationConfigMeta = (id: string, payload: IntegrationConfigMeta, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/integrations/configs/${id}/meta`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name SetIntegrationConfigSources
   * @request PUT:/integrations/configs/{id}/sources
   * @secure
   * @response `204` `void` (empty)
   */
  setIntegrationConfigSources = (id: string, payload: IntegrationConfigSources, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/integrations/configs/${id}/sources`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetIntegrationDashboards
   * @request GET:/integrations/dashboards
   * @secure
   * @response `200` `(DashboardWithId)[]` DashboardWithId
   */
  getIntegrationDashboards = (params: RequestParams = {}) =>
    this.request<DashboardWithId[], any>({
      path: `/integrations/dashboards`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name ForkIntegrationDashboard
   * @request POST:/integrations/{integrationId}/dashboard
   * @secure
   * @response `200` `DashboardWithId` DashboardWithId
   */
  forkIntegrationDashboard = (integrationId: string, params: RequestParams = {}) =>
    this.request<DashboardWithId, any>({
      path: `/integrations/${integrationId}/dashboard`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
 * No description
 *
 * @tags dash
 * @name ConnectIntegration
 * @request POST:/integrations/{slug}/connect
 * @secure
 * @response `200` `{
    kind: "redirect",
    properties: Record<string, any>,

}`
 */
  connectIntegration = (slug: string, payload: IntegrationConnectProps, params: RequestParams = {}) =>
    this.request<
      {
        kind: "redirect";
        properties: Record<string, any>;
      },
      any
    >({
      path: `/integrations/${slug}/connect`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name NewIntegration
   * @request GET:/integrations/{slug}/new
   * @secure
   * @response `200` `IntegrationNextStep` IntegrationNextStep
   */
  newIntegration = (slug: string, params: RequestParams = {}) =>
    this.request<IntegrationNextStep, any>({
      path: `/integrations/${slug}/new`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetLibraries
   * @request GET:/library
   * @secure
   * @response `200` `(LibraryWithSymbols)[]` A list of libraries available to this user
   */
  getLibraries = (query: GetLibrariesParams, params: RequestParams = {}) =>
    this.request<LibraryWithSymbols[], any>({
      path: `/library`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetLibraryNames
   * @request GET:/library/names
   * @secure
   * @response `200` `(string)[]` A list of library names available to this user
   */
  getLibraryNames = (params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/library/names`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetMonitors
   * @request GET:/monitors
   * @secure
   * @response `200` `(Monitor)[]` Monitor
   */
  getMonitors = (params: RequestParams = {}) =>
    this.request<Monitor[], any>({
      path: `/monitors`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateMonitor
   * @request POST:/monitors
   * @secure
   * @response `200` `Monitor` Monitor
   */
  createMonitor = (payload: Monitor, params: RequestParams = {}) =>
    this.request<Monitor, any>({
      path: `/monitors`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateDatadogMonitor
   * @request POST:/monitors/datadog
   * @secure
   * @response `200` `Monitor` Monitor
   */
  createDatadogMonitor = (
    payload: {
      /**
       * A mapping of metric names to metrict metadatas, with the metric name being the objects keys and the metric metadatas being the values.
       * To get a list of a list of metric names from the dd api, see https://docs.datadoghq.com/api/latest/metrics/#get-a-list-of-metrics
       */
      metrics?: DatadogMetricsMetadata;
      /** specifically the value returned when calling https://docs.datadoghq.com/api/latest/monitors/#get-a-monitors-details */
      monitor: Record<string, any>;
    },
    params: RequestParams = {},
  ) =>
    this.request<Monitor, any>({
      path: `/monitors/datadog`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetMonitorHistory
   * @request GET:/monitors/history
   * @secure
   * @response `200` `MonitorHistory` Monitor History
   */
  getMonitorHistory = (query: GetMonitorHistoryParams, params: RequestParams = {}) =>
    this.request<MonitorHistory, any>({
      path: `/monitors/history`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetMonitor
   * @request GET:/monitors/{id}
   * @secure
   * @response `200` `Monitor` Monitor
   */
  getMonitor = (id: string, params: RequestParams = {}) =>
    this.request<Monitor, any>({
      path: `/monitors/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateMonitor
   * @request PUT:/monitors/{id}
   * @secure
   * @response `200` `Monitor` Monitor
   */
  updateMonitor = (id: string, payload: Monitor, params: RequestParams = {}) =>
    this.request<Monitor, any>({
      path: `/monitors/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteMonitor
   * @request DELETE:/monitors/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteMonitor = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/monitors/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name ResolveMonitorAll
   * @request PUT:/monitors/{id}/resolve/all
   * @secure
   * @response `200` `void` Resolved all monitors successfully
   */
  resolveMonitorAll = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/monitors/${id}/resolve/all`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name ResolveMonitor
   * @request GET:/monitors/{id}/resolve/{event_id}
   * @secure
   * @response `303` `void` Redirect to /monitors/{id}
   */
  resolveMonitor = (id: string, eventId: string, params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/monitors/${id}/resolve/${eventId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetNotifiers
   * @request GET:/notifiers
   * @secure
   * @response `200` `(Notifier)[]` Notifier
   */
  getNotifiers = (params: RequestParams = {}) =>
    this.request<Notifier[], any>({
      path: `/notifiers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateNotifier
   * @request POST:/notifiers
   * @secure
   * @response `200` `Notifier` Notifier
   */
  createNotifier = (payload: Notifier, params: RequestParams = {}) =>
    this.request<Notifier, any>({
      path: `/notifiers`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetCustomWebhookTemplate
   * @request GET:/notifiers/customwebhook/template
   * @secure
   * @response `200` `string` Custom webhook template
   */
  getCustomWebhookTemplate = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/notifiers/customwebhook/template`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetNotifier
   * @request GET:/notifiers/{id}
   * @secure
   * @response `200` `Notifier` Notifier
   */
  getNotifier = (id: string, params: RequestParams = {}) =>
    this.request<Notifier, any>({
      path: `/notifiers/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateNotifier
   * @request PUT:/notifiers/{id}
   * @secure
   * @response `200` `Notifier` Notifier
   */
  updateNotifier = (id: string, payload: Notifier, params: RequestParams = {}) =>
    this.request<Notifier, any>({
      path: `/notifiers/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DelNotifier
   * @request DELETE:/notifiers/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  delNotifier = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/notifiers/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name TriggerNotifier
   * @request POST:/notifiers/{id}/trigger
   * @secure
   * @response `200` `Notifier` Notifier triggered successfully
   * @response `400` `void` Bad request, e.g., missing or invalid parameters
   * @response `404` `void` Notifier not found
   * @response `500` `void` Could not process notifier
   */
  triggerNotifier = (id: string, params: RequestParams = {}) =>
    this.request<Notifier, void>({
      path: `/notifiers/${id}/trigger`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOrgs
   * @request GET:/orgs
   * @secure
   * @response `200` `(Org)[]` Org
   */
  getOrgs = (params: RequestParams = {}) =>
    this.request<Org[], any>({
      path: `/orgs`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateOrg
   * @request POST:/orgs
   * @secure
   * @response `200` `Org` Org
   */
  createOrg = (payload: PostOrg, params: RequestParams = {}) =>
    this.request<Org, any>({
      path: `/orgs`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOnboardingStatus
   * @request GET:/orgs/onboarding
   * @secure
   * @response `200` `OnboardingStatus` OnboardingStatus
   */
  getOnboardingStatus = (params: RequestParams = {}) =>
    this.request<OnboardingStatus, any>({
      path: `/orgs/onboarding`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOrg
   * @request GET:/orgs/{id}
   * @secure
   * @response `200` `Org` Org
   */
  getOrg = (id: string, params: RequestParams = {}) =>
    this.request<Org, any>({
      path: `/orgs/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateOrg
   * @request PUT:/orgs/{id}
   * @secure
   * @response `200` `Org` Org
   */
  updateOrg = (id: string, payload: PostOrg, params: RequestParams = {}) =>
    this.request<Org, any>({
      path: `/orgs/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteOrg
   * @request DELETE:/orgs/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteOrg = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/orgs/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetStripeBillingIntent
   * @request GET:/orgs/{id}/billing-intent
   * @secure
   * @response `200` `StripeSetupIntent` StripeSetupIntent
   */
  getStripeBillingIntent = (id: string, params: RequestParams = {}) =>
    this.request<StripeSetupIntent, any>({
      path: `/orgs/${id}/billing-intent`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DowngradePlan
   * @request PUT:/orgs/{id}/downgrade
   * @secure
   * @response `200` `Org` Org
   */
  downgradePlan = (id: string, payload: BillingChange, params: RequestParams = {}) =>
    this.request<Org, any>({
      path: `/orgs/${id}/downgrade`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetInvoices
   * @request GET:/orgs/{id}/invoices
   * @secure
   * @response `200` `Invoices` Invoices
   */
  getInvoices = (id: string, params: RequestParams = {}) =>
    this.request<Invoices, any>({
      path: `/orgs/${id}/invoices`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name ViewSharedAccessKeys
   * @request GET:/orgs/{id}/keys
   * @secure
   * @response `200` `OrgSharedAccessKeys` OrgSharedAccessKeys
   */
  viewSharedAccessKeys = (id: string, params: RequestParams = {}) =>
    this.request<OrgSharedAccessKeys, any>({
      path: `/orgs/${id}/keys`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOrgLicense
   * @request GET:/orgs/{id}/license
   * @secure
   * @response `200` `License` License
   */
  getOrgLicense = (id: string, params: RequestParams = {}) =>
    this.request<License, any>({
      path: `/orgs/${id}/license`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name SetOnboardingData
   * @request POST:/orgs/{id}/onboarding
   * @secure
   * @response `204` `void` onboarding
   */
  setOnboardingData = (id: string, payload: OnboardingPayload, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/orgs/${id}/onboarding`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetPaymentMethod
   * @request GET:/orgs/{id}/payment-method
   * @secure
   * @response `200` `PaymentMethod` PaymentMethod
   */
  getPaymentMethod = (id: string, params: RequestParams = {}) =>
    this.request<PaymentMethod, any>({
      path: `/orgs/${id}/payment-method`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetStripePortal
   * @request GET:/orgs/{id}/portal
   * @secure
   * @response `200` `Portal` Portal
   */
  getStripePortal = (id: string, params: RequestParams = {}) =>
    this.request<Portal, any>({
      path: `/orgs/${id}/portal`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name RotateSharedAccessKeys
   * @request PUT:/orgs/{id}/rotate-keys
   * @secure
   * @response `200` `OrgSharedAccessKeys` OrgSharedAccessKeys
   */
  rotateSharedAccessKeys = ({ id, ...query }: RotateSharedAccessKeysParams, params: RequestParams = {}) =>
    this.request<OrgSharedAccessKeys, any>({
      path: `/orgs/${id}/rotate-keys`,
      method: "PUT",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOrgStatus
   * @request GET:/orgs/{id}/status
   * @secure
   * @response `200` `OrgStatus` OrgStatus
   */
  getOrgStatus = (id: string, params: RequestParams = {}) =>
    this.request<OrgStatus, any>({
      path: `/orgs/${id}/status`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOrgStorage
   * @request GET:/orgs/{id}/storage
   * @secure
   * @response `200` `(Storage)[]` Storage
   */
  getOrgStorage = (id: string, params: RequestParams = {}) =>
    this.request<Storage[], any>({
      path: `/orgs/${id}/storage`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateOrgStorage
   * @request POST:/orgs/{id}/storage
   * @secure
   * @response `200` `Storage` Storage
   */
  createOrgStorage = (id: string, payload: PostStorage, params: RequestParams = {}) =>
    this.request<Storage, any>({
      path: `/orgs/${id}/storage`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateStoragePolicy
   * @request POST:/orgs/{id}/storage-policy
   * @secure
   * @response `200` `Policy` Axiom Policy for customer
   */
  createStoragePolicy = (id: string, payload: PostStorage, params: RequestParams = {}) =>
    this.request<Policy, any>({
      path: `/orgs/${id}/storage-policy`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpgradePlan
   * @request PUT:/orgs/{id}/upgrade
   * @secure
   * @response `200` `Org` Org
   */
  upgradePlan = (id: string, payload: BillingChange, params: RequestParams = {}) =>
    this.request<Org, any>({
      path: `/orgs/${id}/upgrade`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetUsage
   * @request GET:/orgs/{id}/usage
   * @secure
   * @response `200` `Usage` Usage
   */
  getUsage = (id: string, params: RequestParams = {}) =>
    this.request<Usage, any>({
      path: `/orgs/${id}/usage`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetCapabilities
   * @request GET:/rbac/capabilities
   * @secure
   * @response `200` `AvailableCapabilities` Capabilities
   */
  getCapabilities = (params: RequestParams = {}) =>
    this.request<AvailableCapabilities, any>({
      path: `/rbac/capabilities`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetGroups
   * @request GET:/rbac/groups
   * @secure
   * @response `200` `(Group)[]` Groups
   */
  getGroups = (params: RequestParams = {}) =>
    this.request<Group[], any>({
      path: `/rbac/groups`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateGroup
   * @request POST:/rbac/groups
   * @secure
   * @response `200` `Group` Group
   */
  createGroup = (payload: Group, params: RequestParams = {}) =>
    this.request<Group, any>({
      path: `/rbac/groups`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetGroup
   * @request GET:/rbac/groups/{id}
   * @secure
   * @response `200` `Group` Group
   */
  getGroup = (id: string, params: RequestParams = {}) =>
    this.request<Group, any>({
      path: `/rbac/groups/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateGroup
   * @request PUT:/rbac/groups/{id}
   * @secure
   * @response `200` `Group` Group
   */
  updateGroup = (id: string, payload: Group, params: RequestParams = {}) =>
    this.request<Group, any>({
      path: `/rbac/groups/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteGroup
   * @request DELETE:/rbac/groups/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteGroup = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/rbac/groups/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetRoles
   * @request GET:/rbac/roles
   * @secure
   * @response `200` `(Role)[]` Roles
   */
  getRoles = (params: RequestParams = {}) =>
    this.request<Role[], any>({
      path: `/rbac/roles`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateRole
   * @request POST:/rbac/roles
   * @secure
   * @response `200` `Role` Role
   */
  createRole = (payload: Role, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/rbac/roles`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetRole
   * @request GET:/rbac/roles/{id}
   * @secure
   * @response `200` `Role` Role
   */
  getRole = (id: string, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/rbac/roles/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateRole
   * @request PUT:/rbac/roles/{id}
   * @secure
   * @response `200` `Role` Role
   */
  updateRole = (id: string, payload: Role, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/rbac/roles/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteRole
   * @request DELETE:/rbac/roles/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteRole = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/rbac/roles/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetSettings
   * @request GET:/settings
   * @secure
   * @response `200` `Settings` Settings
   */
  getSettings = (params: RequestParams = {}) =>
    this.request<Settings, any>({
      path: `/settings`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOAuthProviders
   * @request GET:/settings/oauth
   * @secure
   * @response `200` `(OAuthProvider)[]` OAuthProvider
   */
  getOAuthProviders = (params: RequestParams = {}) =>
    this.request<OAuthProvider[], any>({
      path: `/settings/oauth`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateOAuthProvider
   * @request POST:/settings/oauth
   * @secure
   * @response `200` `OAuthProvider` OAuthProvider
   */
  createOAuthProvider = (payload: OAuthProvider, params: RequestParams = {}) =>
    this.request<OAuthProvider, any>({
      path: `/settings/oauth`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetOAuthProvider
   * @request GET:/settings/oauth/{id}
   * @secure
   * @response `200` `OAuthProvider` OAuthProvider
   */
  getOAuthProvider = (id: string, params: RequestParams = {}) =>
    this.request<OAuthProvider, any>({
      path: `/settings/oauth/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateOAuthProvider
   * @request PUT:/settings/oauth/{id}
   * @secure
   * @response `200` `OAuthProvider` OAuthProvider
   */
  updateOAuthProvider = (id: string, payload: OAuthProvider, params: RequestParams = {}) =>
    this.request<OAuthProvider, any>({
      path: `/settings/oauth/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteOAuthProvider
   * @request DELETE:/settings/oauth/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteOAuthProvider = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/settings/oauth/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetStarredQueries
   * @request GET:/starred
   * @secure
   * @response `200` `(StarredQueryWithId)[]` StarredQueryWithId
   */
  getStarredQueries = (query: GetStarredQueriesParams, params: RequestParams = {}) =>
    this.request<StarredQueryWithId[], any>({
      path: `/starred`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateStarred
   * @request POST:/starred
   * @secure
   * @response `200` `StarredQueryWithId` StarredQueryWithId
   */
  createStarred = (payload: StarredQuery, params: RequestParams = {}) =>
    this.request<StarredQueryWithId, any>({
      path: `/starred`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetStarred
   * @request GET:/starred/{id}
   * @secure
   * @response `200` `StarredQueryWithId` StarredQueryWithId
   */
  getStarred = (id: string, params: RequestParams = {}) =>
    this.request<StarredQueryWithId, any>({
      path: `/starred/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateStarred
   * @request PUT:/starred/{id}
   * @secure
   * @response `200` `StarredQueryWithId` StarredQueryWithId
   */
  updateStarred = (id: string, payload: StarredQuery, params: RequestParams = {}) =>
    this.request<StarredQueryWithId, any>({
      path: `/starred/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteStarred
   * @request DELETE:/starred/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteStarred = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/starred/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name Events
   * @request POST:/stripe/events
   * @response `200` `void` (empty)
   */
  events = (payload: File, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/stripe/events`,
      method: "POST",
      body: payload,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetTeams
   * @request GET:/teams
   * @secure
   * @response `200` `(Team)[]` Team
   */
  getTeams = (params: RequestParams = {}) =>
    this.request<Team[], any>({
      path: `/teams`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateTeam
   * @request POST:/teams
   * @secure
   * @response `200` `Team` Team
   */
  createTeam = (payload: CreateTeam, params: RequestParams = {}) =>
    this.request<Team, any>({
      path: `/teams`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetTeam
   * @request GET:/teams/{id}
   * @secure
   * @response `200` `Team` Team
   */
  getTeam = (id: string, params: RequestParams = {}) =>
    this.request<Team, any>({
      path: `/teams/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateTeam
   * @request PUT:/teams/{id}
   * @secure
   * @response `200` `Team` Team
   */
  updateTeam = (id: string, payload: Team, params: RequestParams = {}) =>
    this.request<Team, any>({
      path: `/teams/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteTeam
   * @request DELETE:/teams/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteTeam = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/teams/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetApiTokens
   * @request GET:/tokens/api
   * @secure
   * @response `200` `(Token)[]` Token
   */
  getApiTokens = (params: RequestParams = {}) =>
    this.request<Token[], any>({
      path: `/tokens/api`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateApiToken
   * @request POST:/tokens/api
   * @secure
   * @response `200` `CreateTokenResponse` CreateTokenResponse
   */
  createApiToken = (payload: CreateToken, params: RequestParams = {}) =>
    this.request<CreateTokenResponse, any>({
      path: `/tokens/api`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetApiToken
   * @request GET:/tokens/api/{id}
   * @secure
   * @response `200` `Token` Token
   */
  getApiToken = (id: string, params: RequestParams = {}) =>
    this.request<Token, any>({
      path: `/tokens/api/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteApiToken
   * @request DELETE:/tokens/api/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteApiToken = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tokens/api/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name RegenerateApiToken
   * @request POST:/tokens/api/{id}/regenerate
   * @secure
   * @response `200` `CreateTokenResponse` CreateTokenResponse
   */
  regenerateApiToken = (id: string, payload: RegenerateToken, params: RequestParams = {}) =>
    this.request<CreateTokenResponse, any>({
      path: `/tokens/api/${id}/regenerate`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetFineGrainApiTokens
   * @request GET:/tokens/finegrain
   * @secure
   * @response `200` `(FineGrainToken)[]` Token
   */
  getFineGrainApiTokens = (params: RequestParams = {}) =>
    this.request<FineGrainToken[], any>({
      path: `/tokens/finegrain`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateFineGrainApiToken
   * @request POST:/tokens/finegrain
   * @secure
   * @response `200` `CreateFineGrainTokenResponse` CreateFineGrainTokenResponse
   */
  createFineGrainApiToken = (payload: CreateFineGrainToken, params: RequestParams = {}) =>
    this.request<CreateFineGrainTokenResponse, any>({
      path: `/tokens/finegrain`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetFineGrainApiToken
   * @request GET:/tokens/finegrain/{id}
   * @secure
   * @response `200` `FineGrainToken` Token
   */
  getFineGrainApiToken = (id: string, params: RequestParams = {}) =>
    this.request<FineGrainToken, any>({
      path: `/tokens/finegrain/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteFineGrainApiToken
   * @request DELETE:/tokens/finegrain/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteFineGrainApiToken = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tokens/finegrain/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name RegenerateFineGrainApiToken
   * @request POST:/tokens/finegrain/{id}/regenerate
   * @secure
   * @response `200` `CreateFineGrainTokenResponse` CreateFineGrainTokenResponse
   */
  regenerateFineGrainApiToken = (id: string, payload: RegenerateFineGrainToken, params: RequestParams = {}) =>
    this.request<CreateFineGrainTokenResponse, any>({
      path: `/tokens/finegrain/${id}/regenerate`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetPersonalTokens
   * @request GET:/tokens/personal
   * @secure
   * @response `200` `(Token)[]` Token
   */
  getPersonalTokens = (params: RequestParams = {}) =>
    this.request<Token[], any>({
      path: `/tokens/personal`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreatePersonalToken
   * @request POST:/tokens/personal
   * @secure
   * @response `200` `CreateTokenResponse` CreateTokenResponse
   */
  createPersonalToken = (payload: CreateToken, params: RequestParams = {}) =>
    this.request<CreateTokenResponse, any>({
      path: `/tokens/personal`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetPersonalToken
   * @request GET:/tokens/personal/{id}
   * @secure
   * @response `200` `Token` Token
   */
  getPersonalToken = (id: string, params: RequestParams = {}) =>
    this.request<Token, any>({
      path: `/tokens/personal/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdatePersonalToken
   * @request PUT:/tokens/personal/{id}
   * @secure
   * @response `200` `Token` Token
   */
  updatePersonalToken = (id: string, payload: Token, params: RequestParams = {}) =>
    this.request<Token, any>({
      path: `/tokens/personal/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeletePersonalToken
   * @request DELETE:/tokens/personal/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deletePersonalToken = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tokens/personal/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name RegeneratePersonalToken
   * @request POST:/tokens/personal/{id}/regenerate
   * @secure
   * @response `200` `CreateTokenResponse` CreateTokenResponse
   */
  regeneratePersonalToken = (id: string, payload: RegenerateToken, params: RequestParams = {}) =>
    this.request<CreateTokenResponse, any>({
      path: `/tokens/personal/${id}/regenerate`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteCurrentUserToken
   * @request DELETE:/tokens/user/current
   * @secure
   * @response `204` `void` (empty)
   */
  deleteCurrentUserToken = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/tokens/user/current`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Get Usage charts
   *
   * @tags dash
   * @name Usage
   * @request POST:/usage
   * @secure
   * @response `200` `IntegrationConfigMeta` Usage charts
   */
  usage = (payload: UsageDateRange, params: RequestParams = {}) =>
    this.request<IntegrationConfigMeta, any>({
      path: `/usage`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetCurrentUser
   * @request GET:/user
   * @secure
   * @response `200` `User` User
   */
  getCurrentUser = (params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/user`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name LogoutUserEverywhere
   * @request GET:/user/logout
   * @secure
   * @response `200` `void` (empty)
   */
  logoutUserEverywhere = (query: LogoutUserEverywhereParams, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/user/logout`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetUserSettings
   * @request GET:/user/settings
   * @secure
   * @response `200` `UserSettings` User settings
   */
  getUserSettings = (params: RequestParams = {}) =>
    this.request<UserSettings, any>({
      path: `/user/settings`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateUserSettings
   * @request PUT:/user/settings
   * @secure
   * @response `200` `UserSettings` User settings
   */
  updateUserSettings = (payload: UpdateUserSettings, params: RequestParams = {}) =>
    this.request<UserSettings, any>({
      path: `/user/settings`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetUsers
   * @request GET:/users
   * @secure
   * @response `200` `(User)[]` User
   */
  getUsers = (params: RequestParams = {}) =>
    this.request<User[], any>({
      path: `/users`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateUser
   * @request POST:/users
   * @secure
   * @response `200` `User` User
   */
  createUser = (payload: CreateUserRequest, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/users`,
      method: "POST",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name LogoutUsersEverywhere
   * @request POST:/users/logout
   * @secure
   * @response `200` `void` (empty)
   */
  logoutUsersEverywhere = (query: LogoutUsersEverywhereParams, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/users/logout`,
      method: "POST",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetRolesForOrg
   * @request GET:/users/roles
   * @secure
   * @response `200` `(RolesList)[]` RolesList
   */
  getRolesForOrg = (params: RequestParams = {}) =>
    this.request<RolesList[], any>({
      path: `/users/roles`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetSessions
   * @request GET:/users/sessions
   * @secure
   * @response `200` `(Session)[]` Session
   */
  getSessions = (params: RequestParams = {}) =>
    this.request<Session[], any>({
      path: `/users/sessions`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteSession
   * @request DELETE:/users/sessions/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteSession = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/users/sessions/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetUser
   * @request GET:/users/{id}
   * @secure
   * @response `200` `User` User
   */
  getUser = (id: string, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/users/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateUser
   * @request PUT:/users/{id}
   * @secure
   * @response `200` `User` User
   */
  updateUser = (id: string, payload: UserUpdate, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/users/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteUser
   * @request DELETE:/users/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteUser = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/users/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateUserRole
   * @request PUT:/users/{id}/role
   * @secure
   * @response `200` `User` User
   */
  updateUserRole = (id: string, payload: UserRole, params: RequestParams = {}) =>
    this.request<User, any>({
      path: `/users/${id}/role`,
      method: "PUT",
      body: payload,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetVersion
   * @request GET:/version
   * @secure
   * @response `200` `VersionPayload` VersionPayload
   */
  getVersion = (params: RequestParams = {}) =>
    this.request<VersionPayload, any>({
      path: `/version`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetVirtualFields
   * @request GET:/vfields
   * @secure
   * @response `200` `(VirtualFieldWithId)[]` VirtualFieldWithId
   */
  getVirtualFields = (query: GetVirtualFieldsParams, params: RequestParams = {}) =>
    this.request<VirtualFieldWithId[], any>({
      path: `/vfields`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name CreateVirtualField
   * @request POST:/vfields
   * @secure
   * @response `200` `VirtualFieldWithId` VirtualFieldWithId
   */
  createVirtualField = (payload: VirtualField, params: RequestParams = {}) =>
    this.request<VirtualFieldWithId, any>({
      path: `/vfields`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name GetVirtualField
   * @request GET:/vfields/{id}
   * @secure
   * @response `200` `VirtualFieldWithId` VirtualFieldWithId
   */
  getVirtualField = (id: string, params: RequestParams = {}) =>
    this.request<VirtualFieldWithId, any>({
      path: `/vfields/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name UpdateVirtualField
   * @request PUT:/vfields/{id}
   * @secure
   * @response `200` `VirtualFieldWithId` VirtualFieldWithId
   */
  updateVirtualField = (id: string, payload: VirtualField, params: RequestParams = {}) =>
    this.request<VirtualFieldWithId, any>({
      path: `/vfields/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name DeleteVirtualField
   * @request DELETE:/vfields/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteVirtualField = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/vfields/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags dash
   * @name WebhookEvents
   * @request POST:/workos/events
   * @response `200` `void` (empty)
   */
  webhookEvents = (payload: File, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/workos/events`,
      method: "POST",
      body: payload,
      type: ContentType.Json,
      ...params,
    });
}
