import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Clickable } from '../../components/Clickable';
import { Icon } from '../../components/Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/primitives/Popover';
import { SHOW_TANSTACK_DEVTOOLS } from '../../contexts/TanstackQueryTrpc';
import { Logger } from '../logger';
import { safeLocalStorage } from '../safeLocalStorage';
import { cn } from '../styles';

import { type FeatureFlagId } from './config';
import { featureFlagStore } from './FeatureFlagStore';

const HIDE_SELECTOR_KEY = 'featureFlagSelectorHidden';

export const FeatureFlagsSelector = observer(function FeatureFlagSelector() {
  const isHiddenItem = safeLocalStorage.getItem(HIDE_SELECTOR_KEY) === 'true';

  const [isHidden, setIsHidden] = useState(isHiddenItem);

  if (!featureFlagStore.hasAtLeastOneFlag) {
    return null;
  }

  const onHideClick = () => {
    safeLocalStorage.setItem(HIDE_SELECTOR_KEY, 'true');
    setIsHidden(true);
  };

  (window as any).enableFeatureFlagSelector = () => {
    safeLocalStorage.removeItem(HIDE_SELECTOR_KEY);
    setIsHidden(false);
  };

  if (isHidden) {
    Logger.info('Feature flag selector is hidden. Run `enableFeatureFlagSelector()` to show it.');

    return null;
  }

  const { flagsThatCanBeToggled: availableFlags } = featureFlagStore;

  return (
    <div
      className={cn('fixed bottom-3 right-3 z-[9999]', {
        'right-[72px]': SHOW_TANSTACK_DEVTOOLS,
      })}
    >
      <Popover>
        <PopoverTrigger aria-label="Show feature flags">
          <div className="flex h-12 w-12 items-center justify-center rounded-full border-2 border-gray-05 bg-gray-03 hover:bg-gray-05">
            <Icon name="toggle-on" variant="fal" size="2x" color="ok" />
          </div>
        </PopoverTrigger>
        <PopoverContent className="mr-3">
          <div className="p-4">
            <ul className="flex flex-col gap-4">
              <div className="flex w-full justify-center gap-4">
                <p className="font-bold">Feature flags</p>
                <Clickable onClick={onHideClick}>
                  <Icon name="eye" aria-label="hide feature flag selector" />
                </Clickable>
              </div>
              <div className="flex flex-col gap-2">
                {availableFlags.map((flag) => {
                  const hasFlag = featureFlagStore.hasFeatureFlag(flag.id as FeatureFlagId);

                  return (
                    <li className="flex w-full justify-between gap-6" key={flag.id}>
                      <p>{flag.name}</p>
                      <Checkbox
                        className="!p-0"
                        checked={hasFlag}
                        onChange={(value) => featureFlagStore.setFeatureFlag(flag.id as FeatureFlagId, !hasFlag)}
                      />
                    </li>
                  );
                })}
              </div>
              <div className="flex justify-center">
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh page
                </Button>
              </div>
            </ul>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
});
