import * as PopoverPrimitive from '@radix-ui/react-popover';
import React, { type ComponentPropsWithRef } from 'react';

import { FLOATING_CONTAINER_OFFSET } from '../../styles/base/spacing';
import { cn } from '../../util/styles';

import { type RadixClassNameTypeHelper } from './temporaryRadixTypeHelper';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;

export const popoverZoomAnimations = 'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95';
export const resetPopoverZoomAnimations = 'data-[state=closed]:zoom-out-100 data-[state=open]:zoom-in-100';
export const popoverFadeAnimations = 'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0';
export const resetPopoverFadeAnimations = 'data-[state=closed]:fade-out-100 data-[state=open]:fade-in-100';
export const popoverSlideAnimations =
  'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2';
export const resetPopoverSlideAnimations =
  'data-[side=bottom]:slide-in-from-top-0 data-[side=left]:slide-in-from-right-0 data-[side=right]:slide-in-from-left-0 data-[side=top]:slide-in-from-bottom-0';

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> &
    RadixClassNameTypeHelper & {
      animations?: boolean;
      portalProps?: ComponentPropsWithRef<typeof PopoverPrimitive.Portal>;
    }
>(({ className, sideOffset = FLOATING_CONTAINER_OFFSET, animations = true, portalProps, ...props }, ref) => (
  <PopoverPrimitive.Portal {...portalProps}>
    <PopoverPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-[1050] origin-[--radix-popover-content-transform-origin] rounded border bg-elevated shadow-paper-3 outline-none data-[state=open]:animate-in data-[state=closed]:animate-out',
        animations && popoverZoomAnimations,
        animations && popoverFadeAnimations,
        animations && popoverSlideAnimations,
        className
      )}
      align="start"
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
