import union from 'lodash/union';
import { makeAutoObservable, autorun, runInAction } from 'mobx';

import { orgStore } from '../../stores/OrgStore';
import { axiomDebug } from '../axiomDebug';
import { notify } from '../notification';

import { type FeatureFlagId, featureFlagConfig, featureFlags, type FeatureFlag } from './config';

const LOCAL_STORAGE_KEY = `axiom-enabledFeatureFlags-${orgStore?.activeOrgId}`;

class FeatureFlagStore {
  enabledFlagIDs: FeatureFlagId[] = this.getEnabledFlags();

  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.enabledFlagIDs));
    });

    autorun(() => {
      // @ts-expect-error sorry, couldn't find a better way to make the autorun re-run when activeOrgId changes
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _makeAutorunTrigger = orgStore?.activeOrgId;
      runInAction(() => {
        this.enabledFlagIDs = this.getEnabledFlags();
      });
    });
  }

  /**
   * All flags that are available to the current org
   */
  get flagsThatCanBeToggled(): FeatureFlag[] {
    if (!orgStore.activeOrgId) {
      return [];
    }

    const orgFlags = featureFlagConfig[orgStore?.activeOrgId] || [];

    if (orgFlags === 'can-toggle-all') {
      return featureFlags;
    }

    return featureFlags.filter((flag) => orgFlags.some((orgFlag) => orgFlag.id === flag.id && orgFlag.canToggle));
  }

  get hasAtLeastOneFlag() {
    return this.flagsThatCanBeToggled.length > 0;
  }

  hasFeatureFlag(featureFlag: FeatureFlagId) {
    if (!isFeatureFlag(featureFlag)) {
      return false;
    }

    return this.enabledFlagIDs.includes(featureFlag);
  }

  setFeatureFlag(featureFlag: FeatureFlagId, value: boolean) {
    if (!isFeatureFlag(featureFlag)) {
      notify({
        severity: 'error',
        description: `Invalid feature flag: ${featureFlag}`,
        toastId: `invalid-feature-flag-${featureFlag}`,
      });

      return;
    }

    if (value) {
      this.setEnabledFlags([...this.enabledFlagIDs, featureFlag]);
    } else {
      this.setEnabledFlags(this.enabledFlagIDs.filter((flag) => flag !== featureFlag));
    }
  }

  private setEnabledFlags(value: FeatureFlagId[]): void {
    this.enabledFlagIDs = value;
  }

  private getEnabledFlags(): FeatureFlagId[] {
    let alwaysOnFlags: FeatureFlagId[] = [];

    const orgFlags = featureFlagConfig[orgStore?.activeOrgId ?? ''] || [];
    if (orgFlags && orgFlags !== 'can-toggle-all') {
      alwaysOnFlags = orgFlags.filter((flag) => !flag.canToggle).map((flag) => flag.id);
    }

    const storedValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    const storedFlags = storedValue !== null ? JSON.parse(storedValue) : ([] as FeatureFlagId[]);
    const enabledFlags = union(alwaysOnFlags, storedFlags);

    return enabledFlags;
  }
}

function isFeatureFlag(value: string): value is FeatureFlagId {
  return featureFlags.some((flag) => flag.id === value);
}

// export as a singleton because we should only ever use one
export const featureFlagStore = new FeatureFlagStore();

axiomDebug(featureFlagStore);
