import axios from 'axios';
import { action, computed, observable, makeObservable } from 'mobx';

import { axiomDebug } from '../util/axiomDebug';
import { signOut } from '../util/next-auth';
import { identifyUser, trackPage } from '../util/segment';

import { type Org, type User } from './CommonTypes';
import { SimpleOperationsTracker, StoreBase } from './StoreBase';

class UserStatusStore extends StoreBase {
  @observable
  public isSigningOut: boolean = false;
  @observable
  public orgs: Org[] = [];
  @observable
  public user: User | undefined = undefined;
  @observable
  public users: User[] = [];

  constructor() {
    super();

    makeObservable(this);
  }

  @computed
  public get userEmail(): string {
    return this.user?.email || '';
  }

  public getUserStatusOp = new SimpleOperationsTracker('getStatus');

  @action.bound
  public getStatus(orgId?: string): void {
    const op = this.getUserStatusOp.operation;

    // Prevent two calls when Settings pages are hard refreshed.
    if (!op.running) {
      const options = orgId ? { headers: { ['x-axiom-org-id']: String(orgId) } } : {};
      void this.operate(op, axios.get(`/frapi/status`, { ...options, baseURL: '' }), (results) => {
        if (results.data.user) {
          // Identify user to Segment.
          identifyUser(results.data.user);
          trackPage();
        } else {
          // `user` is null which means we're not logged in.
          this.isSigningOut = true;
          void signOut();
        }

        this.orgs = results.data.orgs;
        this.user = results.data.user;
        this.users = results.data.users;
      });
    }
  }
}

// We really only need one global userStatusStore so create that instead of doing a Provider.
export const userStatusStore = new UserStatusStore();

axiomDebug(userStatusStore);
