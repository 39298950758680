import React from 'react';

import { isEnvDev } from '../util/env';
import { errorToString } from '../util/error-to-string';

import { Button } from './Button';

type FriendlyErrorProps = {
  error: Error | string | undefined;
  resetBoundary: () => void;
};

export const FriendlyError = ({ error, resetBoundary }: FriendlyErrorProps) => {
  const errorMessage = errorToString(error).replace('TrackJS Caught: ', '');

  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="absolute right-2 top-2">
        <Button type="axi-secondary" icon="refresh" onClick={() => void resetBoundary()} />
      </div>
      <div className="text-xl">Something went wrong</div>
      {isEnvDev() && <div className="text-red-08">Error: {errorMessage}</div>}
    </div>
  );
};
